// const appName = 'ElectroToss';
const appName = 'Nudiance';

export const helpEmail = 'info@nudiance.com';
export const marketingAndPartnershipEmail = 'info@nudiance.com';
export const appPhoneNumber = '';
export const appLocation = '';
export const stripePaymentLink = 'https://buy.stripe.com/fZe5nv7gx39AdR69AB';

export default appName;
