export const carsObj = {
  Tesla: ['Model S', 'Roadster', 'Model X', 'Model 3', 'Model Y', 'Semi', 'Cybertruck'],
  Maserati: ['Quattroporte', 'Granturismo', 'Coupe', 'Spyder', 'Ghibli', 'Levante', 'Merak',
    'Biturbo', 'TC', '430', '228', 'MC20', 'Grecale', 'GRANTURISMO / GRANCABRIO'],
  'Mercedes-Benz': ['Sprinter', 'SL-Class', 'SLK-Class', 'E-Class', 'CLS-Class', 'CLA-Class',
    'GLA-Class', 'C-Class', 'S-Class', 'SLS-Class', 'B-Class', 'M-Class', 'GL-Class', 'G-Class',
    'GLK-Class', 'CL-Class', 'CLK-Class', 'SLR McLaren', 'R-Class', 'AMG GT',
    'GLC-Class', 'GLE-Class', 'Metris', 'SLC-Class', 'ML-Class', 'GLS-Class', '190',
    '300', '500', 'L1013', 'L1113', 'L1116', 'L1316', 'L1418', 'L1117', 'L1317', 'L1419',
    'LP1419', 'LPS1525', 'LP1219', 'L1319', '280', '240', '380',
    '400', '600', '350', '420', '560', '260', 'A-Class', 'GLB-Class', 'EQC-Class', 'EQS-Class Sedan',
    'EQE-Class Sedan', 'EQB-Class', 'EQS-Class SUV', 'EQE-Class SUV', 'eSprinter'],
  BMW: ['128i', '135i', '328i', 'M3', '335i', '335is', '335d', 'X6', '528i', '535i', 'X5', '550i',
    'X3', '740i', '740Li', '750i', '750Li', '760Li', 'Z4', '750xi', '750Lxi', 'M6', '528xi', '640i',
    '650i', '650xi', 'G 450 X', 'F 800 S', 'F 800 ST', 'F 800 GS', 'F 800 R', 'HP2', 'S 1000 RR', 'R 1200 GS',
    'R 1200 R', 'R 1200 RT', 'K 1300 S', 'K 1300 GT', 'K 1300 R', 'F 650 GS', 'G 650 GS', 'R 900 RT', 'K 1600 GT',
    'K 1600 GTL', 'F 700 GS', 'F 800 GT', 'C 600', 'C 650 GT', 'HP4', 'S 1000 R', 'R nineT', '1M', 'Active E', 'M5', '228i', 'M235i', '320i',
    '328iS', 'Z3', '323i', '323is', '323iC', 'Z8', '525iA', '530iA', '540iA', '525iAT', '540iAT', 'Alpina', '645i', 'X4', '335'],
  Bugatti: ['Veyron', 'Chiron', 'EB110', 'Chiron Pur Sport', 'Chiron Supersport'],
  Ford: ['Crown Victoria', 'Focus', 'Fusion', 'Mustang', 'Taurus', 'E-150', 'Edge', 'Escape',
    'Expedition', 'Explorer', 'F-150', 'Flex', 'Ranger', 'Explorer Sport Trac', 'F-250', 'F-350', 'F-450',
    'F-550', 'F-650', 'F-750', 'Transit Connect', 'E-250', 'E-350', 'E-450', 'Expedition MAX', 'Thunderbird',
    'GT', 'Five Hundred', 'Excursion', 'Freestyle', 'Freestar', 'Motorhome Chassis', 'Taurus X', 'Fiesta',
    'Commercial Chassis', 'C-Max', 'Transit', 'Escort', 'ZX2', 'Windstar', 'E-550', 'B-750', 'F-800', 'Explorer Sport',
    'Aspire', 'Probe', 'Contour', 'Bronco', 'Aerostar', 'F-150 Heritage', 'L8501', 'LT8501', 'L9501', 'LT9501', 'L8511',
    'LT8511', 'L9511', 'LT9511', 'L8513', 'LT8513', 'L9513', 'LT9513', 'L9522', 'LT9522', 'A8513', 'AT8513', 'A9513',
    'AT9513', 'A9522', 'AT9522', 'B800', 'F-Super Duty', 'F-700', 'P700', 'P800', 'FT900', 'L8000', 'L9000', 'LL9000',
    'LLA9000', 'LLS9000', 'LS8000', 'LS9000', 'LT8000', 'LT9000', 'LTS8000', 'LTS9000', 'LTL9000', 'LTLA9000', 'LTLS9000',
    'LA8000', 'LA9000', 'LTA9000', 'LN7000', 'LN8000', 'LN9000', 'LNT8000', 'LNT9000', 'CF8000', 'CFT8000', 'CF7000', 'Festiva',
    'Low Cab Forward', 'Tempo', 'B600', 'B700', 'F-600', 'Recreational Vehicle', 'F-590', 'P600', 'CL9000', 'CLT9000', 'Bronco II',
    'FT800', 'CT8000', 'C800', 'C8000', 'Laser', 'LTD', 'Fairmont', 'Granada', "'34", 'CF6000', 'B7000', 'C7000', 'F7000', 'F8000',
    'FT8000', 'Courier', 'B6000', 'C600', 'C700', 'F6000', 'LN600', 'LN700', 'L800', 'LT800', 'LN800', 'LNT800', 'E-100', 'F-100',
    'CT800', 'Ecosport', 'Expedition EL', 'Bronco Sport', 'Mustang Mach-E', 'Maverick', 'GT MKII'],
  Buick: ['LaCrosse', 'Regal', 'Verano', 'Enclave', 'Encore', 'Lucerne', 'Allure', 'Terraza', 'Rainier', 'Rendezvous',
    'Park Avenue', 'LeSabre', 'Century', 'Incomplete', 'Riviera', 'Skylark', 'Coachbuilder', 'Roadmaster', 'Cascada',
    'Envision', 'Reatta', 'Electra', 'Skyhawk', 'Somerset', 'Regal TourX', 'Encore GX', 'Envista'],
  Cadillac: ['ATS', 'CTS', 'ELR', 'XTS', 'Escalade', 'SRX', 'STS', 'DTS', 'XLR', 'Limousine', 'Hearse',
    'Escalade ESV', 'Deville', 'Eldorado', 'Catera', 'Seville', 'Commercial Chassis', 'Fleetwood', 'CT6', 'XT5', 'Funeral Coach', 'Armored Vehicle', 'Deville Chassis',
    'Stretch Limousine', '60 Special', 'Allante', 'Brougham', 'Cimarron', 'Professional Chassis', 'XT4', 'XT6', 'CT4', 'CT5', 'Lyriq'],
  Chevrolet: ['Aveo', 'Camaro', 'Caprice Police Vehicle', 'Corvette', 'Cruze', 'Impala', 'Malibu', 'Sonic', 'Spark', 'SS', 'Volt', 'Captiva Sport', 'Equinox', 'Express', 'Orlando', 'Silverado',
    'Suburban', 'Tahoe', 'Traverse', 'Tracker', 'City Express', 'Geo Prizm', 'Avalanche', 'Matiz', 'Colorado', 'Cobalt', 'Optra', 'Kalos', 'Trailblazer', 'HHR', 'Uplander', 'W4', 'W5', 'Monte Carlo', 'SSR',
    'Epica', 'Cavalier', 'U100', '3 ton', 'Astro Van', 'Venture',
    'B7', 'Lumina', 'Alero', 'Caprice', 'Corsica', 'Beretta', 'Hearse/Limo', 'Metro', 'GMT-400', 'Military Truck', 'Forward Control', 'Trax', 'S-10 Pickup', 'C/K Pickup', 'P - Series',
    'Lumina APV', 'Blazer', 'Impala Limited', 'Bolt EV', '3500HD', '5500HD', '3500 / 4500', '4500HD', '4500XD', '5500XD', 'T-Series', 'C4', 'C5', 'C6', 'C7',
    'C8', 'W7', 'Hearse/Limo Commercial Chassis', 'W3', 'W6', 'D7', 'P6S', 'S-10 Blazer', 'P Truck Forward', 'Celebrity', 'Geo Spectrum', 'G-Series', 'Cutaway Chassis', 'Motorhome Chassis', 'Chevette',
    'Hi-Cube', 'Cutaway Van', 'S7', 'Geo Sprint', 'Nova', 'S6', 'R Conventional', 'V Conventional',
    'Citation', 'Bus Chassis', 'Heavy Conventional', 'Aluminum Tilt', 'El Camino', 'LUV',
    'Malibu Classic', '3500', '4500', 'Bolt Incomplete', 'FTR / 6500XD', '6500XD', 'Silverado HD', 'Silverado LD', '7500XD', 'GM51P', 'W3500/W4500', 'W5500/W5500 HD', 'Onix', 'Silverado LTD', 'Bolt EUV', '3500HG', '4500HG', '5500HG',
    '5500XG', 'Blazer EV', 'Silverado EV', 'Equinox EV'],
  GMC: ['Acadia', 'Savana', 'Sierra', 'Terrain', 'Yukon', 'Canyon', 'Envoy', 'Yukon XL', 'W4',
    'Safari', 'Electric Vehicle', 'GMT-400', 'Sonoma', 'Suburban', 'C/K Pickup', 'Jimmy Utility',
    // eslint-disable-next-line max-len
    'T-Series', 'C4', 'C5', 'C6', 'C7', 'C8', 'W5', 'W7', 'W3', 'B7', 'W6', 'P6S', 'D7', 'P Truck Forward', 'Motorhome Chassis', 'Cutaway Chassis', 'S15 Utility', 'Vandura', 'Cutaway Van', 'Rally', 'Magna Van', 'Typhoon', 'Value Van', 'Hi-Cube', 'S7', 'Geo Tracker', 'S6', 'Jimmy', 'R Conventional', 'V Conventional', 'S15 Pickup', 'Forward Control', 'Aluminum Tilt', 'Heavy Conventional', 'Bus Chassis', 'Caballero', 'Transit Coach', 'Articulated Coach', 'Silverado Medium Duty (GM515)', 'Sierra HD', 'Sierra Limited', 'W3500/W4500', 'W5500/W5500 HD', 'Silverado Medium Duty (GM51P)', 'Hummer EV SUV', 'Cruise Origin AV', 'Hummer EV Pickup', 'Sierra EV'],
  Mazda: ['Tribute', 'MX-5', 'RX-8', 'Mazda3', 'Mazda5', 'CX-7', 'CX-9', 'Mazda6', 'Mazda2', 'MPV',
    'CX-5', 'B-Series', 'Protege', 'Millenia', '626', '323', 'MX-3', '929', 'RX-7', 'MX-6', 'CX-3', 'GLC',
    'Navajo', 'CX-30', 'MX-30', 'CX-50', 'CX-90'],
  Honda: ['Accord', 'Civic', 'Pilot', 'CR-V', 'Ridgeline', 'Element', 'Odyssey', 'Insight', 'FCX Clarity', 'Fit', 'CR-Z', 'S2000', 'PCX150', 'CB1100', 'CRF250', 'CTX1300', 'CTX700', 'GOLDWING', 'NC700X',
    'METROPOLITAN', 'NPS50 (Ruckus)', 'ST1300PA', 'INTERCEPTOR', 'ST1300', 'Silverwing', 'CBR250', 'VFR1200F', 'NT700V', 'PCX125', 'SH150I', 'VTX1800F', 'CB250 (Nighthawk)', 'ELITE 80', 'Helix',
    'VTX1300C', 'VTX1300R', 'VTX1300S', 'VTX1800C', 'Shadow VLX', 'ST1300P', 'CB600 (599)', 'NIGHTHAWK 750', 'CBR1100XX',
    'CBR954RR', 'ST1100P', 'ST1100', 'ELITE 50',
    'CBR900', 'Pacific Coast', 'VFR 750F', 'XR250L', 'Shadow 1100', 'Shadow', 'CT70', 'RC 45', 'Hawk GT', 'CB-1',
    'ELITE 250', 'Tourist Trophy', 'NS50F', 'NX250', 'NX125', 'SA50', 'SB50P', 'VFR750R (RC30)', 'VTR', 'XL600V',
    'VT1100C', 'ELITE', 'TRANSALP', 'NX650', 'Prelude', 'Passport', 'Crosstour', 'HR-V', 'del Sol', 'CBR650',
    'NC700XD', 'CB500', 'CRF50', 'XR400R', 'TRX700XX', 'TRX450ER', 'FourTrax', 'TRX400X', 'Sportrax', 'TRX450R',
    'CRF70F', 'CRF100F', 'CR250', 'SA50P', 'CR125', 'XR250R', 'MUV700 (Big Red)', 'EV Plus', 'TRX90', 'XR600R',
    'TRX200D', 'TRX200DN', 'TRX250', 'TRX300', 'TRX300EX', 'TRX300EXN', 'TRX300FW', 'TRX300FWN', 'TRX300N',
    'TRX400FW', 'TRX400FWN', 'TRX450S', 'TRX450ES', 'TRX400EX', 'NC700XL (NC700X)', 'NC700XD (NC700X DCT)',
    'NC700XDL (NC700X DCT)', 'CMX250 (Rebel)', 'NC700JD (NM4)', 'VT1300 (Stateline)', 'XR650', 'TRX420 (FourTrax Rancher)',
    'TRX420', 'TRX500 (FourTrax Foreman)', 'TRX680 (Rincon)', 'TRX680 (FourTrax Rincon)', 'NCH50 (GIORNO)',
    'NCH50 (METROPOLITAN)', 'NSS300 (FORZA)', 'NSS300A (FORZA)', 'CRF110', 'CRF125', 'NC750JD/NM4', 'MRT260/COTA 4RT260',
    'CBR500', 'NC750X', 'CBR300', 'NC700XD/NC700X', 'NC700XDL/NC700X', 'TRX250X', 'TRX450R/TRX450ER',
    'TRX250 (FourTrax Recon)', 'CBR125', 'MSX125 (Grom)', 'NC750SA', 'VFR800F', 'VFR800F (INTERCEPTOR)',
    'VFR1200FD/VFR1200FA', 'ST1300A', 'VT1300 (Sabre)', 'CBR1000', 'VFR1200FA', 'VFR1200FD/VFR1200F', 'ST1300A/ST1300',
    'CRF80F', 'NC700SA/NC700S', 'VFR1200FAD/VFR1200FD', 'CBF1000A', 'VT750 (Shadow Aero)', 'CBF600SA', 'VFR1200FAD',
    'NT700VA/NT700V', 'MRT260/COTA 4RT/4RT 260', 'VT1300 (Fury)', 'VT750 (Shadow Phantom)', 'VT750', 'VT750 (Shadow Ace 750)',
    'VT750 (Shadow)', 'VT1300', 'FSC600 (Silver Wing)', 'NHX110 (ELITE 110)', 'SH150D/SH150i', 'VT750 (Aero)',
    'VT1300 (Interstate)', 'NSA700A/DN-01', 'VFR800 (INTERCEPTOR)', 'VFR800A (INTERCEPTOR)', 'XL1000V (VARADERO)',
    'CRF150', 'TRX300X', 'TRX450ERB', 'TRX500', 'CHF50 (Metropolitan)', 'CHF50 (Jazz)', 'GL1100 (GOLDWING)',
    'GL1200 (GOLDWING)', 'VT600C (SHADOW VLX)', 'VT1100C (SHADOW SPIRIT 1100)', 'VT1100C2 (SHADOW SABRE)', 'VT1100D2',
    'NRX1800 (VALKYRIE RUNE)', 'VTX1800F1/VTX1800F', 'VTX1800F3/VTX1800F', 'VTX1800N/VTX1800 NEO RETRO',
    'VTX1800N1/VTX1800N', 'VTX1800N3/VTX1800N', 'VTX1800R/VTX1800 RETRO', 'VTX1800S/VTX1800 SPOKE',
    'VTX1800T/VTX1800T TOURER', 'VTX1800T1/VTX1800T', 'CB599', 'VFR800', 'VFR800A', 'VFR800AT', 'CB900',
    'CB900 (919)', 'VTR1000S (RC51)', 'XL200R', 'NT650J', 'CR85', 'CG150ESD', 'XR200R', 'NSR50MIN', 'TRX700EX',
    'TRX350FE', 'TRX350FM', 'TRX350TE', 'TRX350TM', 'TRX400FA', 'TRX400FG (FourTrax 400)', 'TRX450FM', 'TRX500 (FourTrax)',
    'TRX650FA (Rincon)', 'TRX650FG (Rincon)', 'NSS250A (REFLEX)', 'PS250 (BIG RUCKUS)', 'VT600CD (SHADOW VLX DELUXE)',
    'VT750 (A.C.E.)', 'VT750 (Shadow Spirit 750)', 'VT750 (Shadow Ace/Aero)', 'VT1100C2D (SABRE)', 'VTX1800C1/VTX1800C',
    'VTX1800FD', 'VTX1800F2/VTX1800F', 'VTX1800ND', 'VTX1800N2/VTX1800N', 'VTX1800R1/VTX1800R', 'VTX1800R2/VTX1800R',
    'VTX1800R3/VTX1800R', 'VTX1800TD', 'VTX1800T2/VTX1800T', 'VFR800AT (INTERCEPTOR ABS)', 'TRX250 (SportTrax 250)',
    'TRX300EX (SportTrax 300)', 'TRX400EX (SportTrax 400)', 'TRX450ERB (SportTrax 450)', 'TRX450ER (SportTrax 450)',
    'TRX250 (FourTrax 250)', 'TRX400FA (FourTrax Rancher AT)', 'TRX400FGA (FourTrax Rancher AT)', 'CH80 (Elite)',
    'CN250 (Helix)', 'NSS250AS (REFLEX SPORT)', 'NSS250S (REFLEX SPORT)', 'NSS250 (REFLEX)', 'VT600C (VLX)', 'VTX1300T',
    'VTX1800C2/VTX1800C', 'VTX1800C3/VTX1800C', 'VTX1800S1/VTX1800S', 'VTX1800S2/VTX1800S', 'VTX1800S3/VTX1800S',
    'VFR800 (SPORT TOURER)', 'VFR800A (SPORT TOURER)', 'CB900 (Hornet)', 'RVT1000R (RC51)', 'TRX90 (Fourtrax 90)',
    'TRX350FE (FourTrax Rancher)', 'TRX350FM (FourTrax Rancher)', 'TRX350TE (FourTrax Rancher)', 'TRX350TM (FourTrax Rancher)',
    'VTR1000F (SUPERHAWK)', 'NRX1800DA (VALKYRIE RUNE)', 'NRX1800DB (VALKYRIE RUNE)', 'NRX1800EA (VALKYRIE RUNE)',
    'NRX1800EB (VALKYRIE RUNE)', 'VTX1800C1 (VTX)', 'VTX1800C2 (VTX)', 'VTX1800C3 (VTX)', 'VTX1800F1 (VTX)', 'VTX1800F2 (VTX)',
    'VTX1800N3 (VTX)', 'VTX1800N2 (VTX)', 'VTX1800N1 (VTX)', 'VTX1800R3 (VTX)', 'VTX1800R2 (VTX)', 'VTX1800R1 (VTX)',
    'VTX1800S3 (VTX)', 'VTX1800S2 (VTX)', 'VTX1800S1 (VTX)', 'VTX1800F3 (VTX)', 'RC1000VS (RC213V-S)', 'TRX90 (SportTrax 90)',
    'TRX250 (Recon)', 'TRX400FW (FourTrax Foreman 400)', 'TRX450FE (FourTrax Foreman)', 'TRX450FM (FourTrax Foreman)',
    'TRX650FA (FourTrax Rincon)', 'TRX650FGA (FourTrax Rincon)', 'CRF1000', 'VFR1200X', 'VFR1200XD', 'CHF50 (Metropolitan II)',
    'NRX1800BA (VALKYRIE RUNE)', 'NRX1800BB (VALKYRIE RUNE)', 'VTX1800C (VTX)', 'TRX400FGA', 'CB750 (Nighthawk 750)',
    'VF750C (MAGNA)', 'CBR900/CBR954', 'CBR900/CBR929', 'VTX1800R (VTX)', 'VTX1800S (VTX)', 'GL1500 (Valkyrie)', 'XR70R',
    'XR50', 'CR80', 'XR80R', 'XR100R', 'VFR800Fi (INTERCEPTOR)', 'ST1100A', 'VT1100C3 (SHADOW AERO)', 'GL1500C (VALKYRIE)',
    'GL1500CD (VALKYRIE TOURER)', 'GL1500CT (VALKYRIE TOURER)', 'GL1500CF (VALKYRIE INTERSTATE)', 'GL1500SE (GOLD WING SE)',
    'VT600CD2', 'VT1100T (SHADOW ACE TOURER)', 'CR500R', 'VF750C2 (MAGNA)', 'GL1500A (GOLD WING ASPENCADE)', 'VT1100C2 (SHADOW ACE)',
    'Z50R', 'PC800 (PACIFIC COAST)', 'XR200', 'GL1500i', 'EZ90', 'RVF750R (RC45)', 'TRX400FG', 'Cota 300RR', 'Honda Utility Vehicle', 'CBR600',
    'Clarity', 'CH250', 'SE50PI', 'CH150', 'SE50H', 'SE50PH', 'NQ50', 'CMX450C', 'VT700C (Shadow)', 'VR700FII', 'XL250R', 'XL600R', 'TLR200', 'CN250',
    'NB50', 'VF1000R', 'VF500F', 'CB450', 'TG50', 'CB700', 'VF1100C', 'VT500', 'NB50M', 'XL80', 'XL100S', 'VF1100S', 'VF1000F', 'XL350R', 'NH80', 'CB125',
    'VF700C (Magna)', 'NN50MD', 'XL125', 'CMX300 (Rebel 300)', 'CMX500 (Rebel500)', 'NCW50/Metropolitan', 'CRF1000 (Africa Twin)', 'CBF300', 'CRF450', 'Z125 (Monkey)', 'GL1800', 'C125 (Super Cub)', 'Pioneer', 'CRF1100 (Africa Twin)', 'VT800C', 'CH125', 'CM200', 'CM250C', 'CX500', 'CM450', 'VF500C',
    'XL500S', 'XR500R', 'CB750', 'CB650', 'VF750S', 'CB550', 'CX650', 'Accord Crosstour', 'SXS1000S4 (Talon 1000X-4)', 'ADV150', 'FIREBLADE', 'CBX', 'TRX500 (FourTrax Foreman Rubicon)',
    'Cota 300RR (MRT300)', 'WW150/PCX150', 'SXS1000M (Pioneer 1000)', 'SXS700 (Pioneer 700)', 'SXS500 (Pioneer 500)', 'TRX420 (Rancher)', 'TRX500 (Foreman)',
    'TRX500 (Foreman Rubicon)', 'TRX500 (Rubicon)', 'CB300', 'CB1000', 'Trail 125', 'CMX1100A (REBEL1100)', 'CMX1100D (REBEL1100 DCT)', 'CRF300L', 'Monkey', 'CIVIC SI', 'Navi', 'CMX300', 'PCX', 'Ruckus', 'SXS1000S2 (Talon 1000)', 'TRX520', 'SXS520', 'ATC350X', 'ATC250', 'ATC200',
    'CM400', 'VF700S (Sabre)', 'VF700F (Interceptor)', 'Civic Type R', 'XR150L', 'NU50', 'NC50', 'XR350R', 'SCL500', 'ADV160', 'Prologue',
    'NCW50/Giorno', 'CRF230L', 'CRF230M', 'CRF230F', 'CRF125F', 'CRF125FB', 'CRF150F', 'CRF150R', 'RF150RB', 'CRF150RB', 'CRF250L', 'CRF250R', 'CRF250X', 'CRF50F', 'CRF110F', 'CRF450R', 'CRF450X', 'CRF250RX', 'CRF250F', 'CRF450RX',
    'CRF450RWE', 'CRF1000A', 'CRF1000D', 'CRF1000LA', 'CRF1000LD', 'CRF150RE', 'CRF150RBE', 'CRF250RB', 'CRF450RB',
    'CRF250RL', 'CRF250RLA', 'CRF250LA', 'CRF300LR', 'CRF450RL', 'CRF1100D', 'CRF1100A', 'CRF1100A4', 'CRF1100D4',
    'CRF1000A2', 'CRF1000D2', 'CRF11004D', 'CRF11004', 'CRF450L', 'CRF1100LD', 'CRF1100L', 'CRF1100L4D', 'CRF1100L4', 'CRF1000AS', 'CRF1000ASD', 'XR650L', 'CTX700D', 'CTX700ND', 'CBR125R', 'CBR600RR', 'CBR600RA', 'CBR500R', 'CBR500RA', 'CB500FA', 'CB500F', 'CB500XA', 'CB500X', 'CBR650F', 'CBR650FA', 'CBR1000RR', 'CBR1000RA', 'CBR1000S', 'CB1000R', 'CB1000RA', 'CB1100A', 'CB1100SA', 'CBR250R', 'CBR250RA', 'CBR300R', 'CBR300RA', 'CB300F', 'CB300FA', 'CB650F', 'CB650FA', 'CBF300N', 'CB300R', 'CBR650R', 'CB650R'],
  Acura: ['ZDX', 'RDX', 'RL', 'TL', 'TSX', 'MDX', 'RLX', 'ILX', 'RSX', 'Integra', 'CL', 'NSX', 'Legend', 'TLX',
    'SLX', 'Vigor', 'TLX TYPE S ', 'MDX Type S', 'NSX Type S', 'INTEGRA TYPE S'],
  Chrysler: ['Cirrus', 'Sebring', '300', 'Town and Country', 'Grand Voyager', '200', 'PT Cruiser', 'Crossfire',
    'Pacifica', 'Aspen', 'Concorde', 'Intrepid', 'New Yorker', 'LHS', 'Vision', 'Stratus', 'Viper',
    'Neon', 'Shadow', 'Prowler', 'Caravan', 'TC', 'LeBaron', 'Concorde/LHS', 'Daytona', 'Dynasty', 'Imperial', 'SALON', 'LASER', 'NEWPORT', 'FIFTH AVENUE', 'Conquest', 'EXECUTIVE', 'E-CLASS', 'Cordoba', 'Voyager', '300C', 'Grand Caravan'],
  Nissan: ['GT-R', 'Versa', 'Versa Note', 'Altima', 'Leaf', '370Z', 'Sentra', 'Pathfinder', 'Juke', 'Rogue', 'Murano', 'Armada', 'Xterra', 'Quest', 'NV200', 'Frontier', 'Titan', 'NV', 'Maxima', '350Z', 'X-Trail', 'Cube', '240SX', '300ZX', 'Pickup', 'Altra-EV', 'NX', 'Stanza', 'Axxess', 'PULSAR', 'Van', 'Stanza Wagon', '200SX', 'Rogue Sport', 'Sentra Classic', 'Altra', 'Micra', 'Rogue Select', 'Kicks', 'NISSAN Z', 'ARIYA'],
  Dodge: ['Avenger', 'Challenger', 'Charger', 'Caliber', 'Viper', 'Ram', 'Journey', 'Nitro', 'Dakota', 'Caravan/Grand Caravan', 'Ram Chassis Cab', 'Dart', 'Sprinter', 'Durango', 'Chassis with Powertrain', 'Magnum', 'Intrepid', 'Stratus', 'Neon', 'Colt', 'Stealth', 'Ramcharger', 'Ram Van', 'Ram Wagon', 'Spirit', 'SHADOW', 'Dynasty', 'Daytona', 'Monaco', 'OMNI', 'SHELBY', 'ARIES', '600', 'DIPLOMAT', 'LANCER', 'Conquest', '400', 'Mirada', 'ST.REGIS', 'Caravan', 'Grand Caravan', 'RAM 50', 'D-Series', 'W-Series', 'Raider', 'Mini Ram', 'Royal Mini Ram Van', 'D50', 'Rampage', 'RD200 / RD250', 'HORNET'],
  Infiniti: ['QX50', 'Q40', 'Q50', 'Q60', 'Q70', 'QX70', 'QX80', 'QX60', 'Q70L', 'Q45', 'G35', 'M35', 'M45', 'FX35', 'FX45', 'QX56', 'G37', 'EX35', 'FX50', 'M56', 'M37', 'G25', 'M35h', 'FX37', 'JX35', 'J30', 'G20', 'I35', 'I30', 'QX4', 'M30', 'QX30', 'QX55'],
  Jeep: ['Wrangler', 'Liberty', 'Cherokee', 'Compass', 'Patriot', 'Commander', 'Grand Cherokee', 'Renegade', 'Grand Wagoneer', 'Comanche', 'Wagoneer', 'J-10', 'J-20', 'CJ-7', 'CJ-8 Scrambler', 'CJ-5', 'CJ-6', 'Wrangler JK', 'Gladiator', 'Grand Cherokee L', 'Wagoneer L', 'Grand Wagoneer L'],
  Mitsubishi: ['Raider', 'Outlander', 'Lancer', 'Galant', 'Eclipse', 'Endeavor', 'Montero', 'Eclipse Spyder', 'RVR', 'i-MiEV', 'Mirage', 'Expo', '3000 GT', '3000 GT Spyder', 'Diamante', 'Truck', 'Montero Sport', 'Lancer SportBack', 'Lancer Evolution', 'Mirage G4', 'Outlander Sport', 'Precis', 'Space Wagon', 'Wagon', 'Sigma', 'Starion', 'Van', 'Tredia', 'Cordia', 'Mighty Max', 'SPX', 'SP', 'ECLIPSE CROSS', 'Low Speed Vehicle'],
  Volkswagen: ['Routan', 'Golf', 'Passat', 'Phaeton', 'Touareg', 'Jetta', 'GTI', 'R32', 'Jetta Wagon', 'Rabbit', 'Eos', 'Golf SportWagen', 'Golf III', 'Corrado', 'EuroVan', 'e-Golf', 'Beetle', 'CC', 'Tiguan', 'Jetta SportWagen', 'Cabrio', 'Golf GTI', 'Golf R', 'CABRIOLET', 'FOX', 'Quantum', 'Scirocco', 'Vanagon', 'Dasher', 'KOMBI', 'Atlas', 'Golf Alltrack', 'Tiguan Limited', 'Arteon', 'GLI', 'Atlas Cross Sport', 'ID.4', 'Taos', 'Atlas Cross Sport 4Motion', 'Tiguan 4Motion', 'Atlas 4Motion', 'Taos 4Motion', 'Jetta GLI', 'Arteon 4Motion'],
  Volvo: ['S60', 'S80', 'V60', 'XC60', 'XC70', 'S40', 'V50', 'C70', 'V70', 'XC90', 'C30', '9700', '940 Series', '960 Series', '850 Series', 'V60CC', 'S60 Cross Country', 'S90', 'V90', 'V90CC', '240 Series', '740 Series', '780 Series', '760 Series', 'B10M', 'V40', 'S70', 'Cab Over Engine HT', 'Cab Over Engine LT', 'F12 w/F7 Cab', 'F6 w/F7 Cab', 'Cab Behind Engine', 'Volvo Trailers', '260 Series', 'XC40', 'VT', 'VS', 'C70 / C30', 'C40', 'B12R', 'B7R', 'B12B', 'B12M', 'B9TL', 'B9R', 'B9S', 'B9L', 'BXR', 'B5R', 'BRLH', 'BXXR', 'BFE', 'BXRB', 'B8R', 'BRH', 'B5TL', 'BE', 'B8L', 'B13R'],
  'Alfa Romeo': ['4C', '8C Competizione Spider', '164', 'Giulia (952)', 'Spider', 'Milano', 'GTV6', 'Stelvio', 'TONALE'],
  Fiat: ['500L', '500', 'Freemont', '500X', '124 Spider', 'Spider 2000', 'X 1/9', 'Brava', 'Strada', 'Ducato'],
  Ram: ['Promaster City', '1500', '2500', '3500', '4000', '4500', '5500', 'ProMaster 1500', 'ProMaster 2500', 'ProMaster 3500', 'Cargo Van'],
  Hyundai: ['Equus', 'Santa Fe', 'Tucson', 'Veloster', 'Genesis Coupe', 'Sonata', 'Elantra', 'Entourage', 'Azera', 'Veracruz', 'Accent', 'Genesis', 'Tiburon', 'Elantra Touring', 'XG 350', 'Excel', 'Scoupe', 'XG300', 'Elantra GT', 'Pony', 'Stellar', 'Santa Fe Sport', 'Ioniq', 'Kona', 'Santa Fe XL', 'Nexo', 'Palisade', 'Venue', 'Kona Electric', 'Veloster N', 'Santa Cruz', 'IONIQ 5', 'Elantra N', 'Xcient', 'Ioniq 6', 'Kona N'],
  Lamborghini: ['Murcielago', 'Gallardo', 'Diablo', '147', 'Aventador', 'Huracan', 'URUS', 'Roadster', 'Huracan STO',
    'Revuelto'],
  Lexus: ['GS', 'LS', 'SC', 'IS', 'LX', 'RX', 'GX', 'ES', 'CT', 'HS', 'RC', 'LFA', 'NX', 'RC-F', 'LC', 'UX', 'RZ 450e', 'TX'],
  Toyota: ['Scion xA', 'Scion tC', 'Corolla', 'PRIUS', 'Scion xB', 'Land Cruiser', 'Highlander', '4-Runner', 'RAV4',
    'Tacoma', 'Scion FR-S', 'FJ Cruiser', 'Yaris', 'Avalon', 'Corolla Matrix', 'Tundra', 'Sienna', 'Camry', 'Camry Solara',
    'Sequoia', 'Scion xD', 'Venza', 'FCHV-adv', 'Tercel', 'Celica', 'Supra', 'MR2', 'Paseo', 'Echo', 'Cressida',
    'Cargo Van', 'Previa', 'T100', 'Mirai', 'Scion iQ', 'Scion iM', '86', 'Scion iA', 'Prius V', 'Prius C', 'Prius Plug-in', 'Pick-Up',
    'Van', 'Starlet', 'Corona', 'COROLLA iM', 'C-HR', 'Prius Prime', 'Yaris iA', 'RAV4 Prime',
    'COROLLA CROSS', 'GR86', 'bZ4X', 'CROWN', 'GR COROLLA', 'Grand Highlander'],
  Subaru: ['Legacy', 'Outback', 'Forester', 'Impreza', 'XV CrossTrek', 'WRX', 'BRZ', 'Baja', 'B9 Tribeca', 'SVX',
    'Crosstrek', 'Justy', 'Loyale', 'Brat', 'XT', 'XT6', 'DL', 'GL', 'GL-10', 'RX', 'Standard', 'GLF', 'Ascent', 'Solterra'],
  'Land Rover': ['Discovery Sport', 'LR2', 'Range Rover Evoque', 'LR4', 'Range Rover Sport', 'Range Rover', 'LR3',
    'Discovery', 'Freelander', 'Defender', 'Range Rover Velar', 'New Range Rover'],
  Jaguar: ['XJ', 'XF', 'XK', 'X-Type', 'S-Type', 'F-Type', 'Vanden Plas', 'XK8', 'XJR', 'XE', 'F-Pace', 'Super V8', 'XJ8', 'XJ6', 'XJS', 'E-PACE', 'I-PACE', 'XJ12'],
  Kia: ['Rio', 'Soul', 'Borrego', 'Forte', 'Rondo', 'Optima', 'Sedona', 'Sorento', 'Sportage', 'Cadenza', 'K900',
    'Sephia', 'Spectra', 'Amanti', 'Spectra LD', 'Sephia II/Spectra', 'Forte Koup',
    'Soul EV', 'Niro', 'Stinger', 'Telluride', 'Seltos', 'K5', 'Carnival', 'EV6', 'EV9'],
  Audi: ['TT', 'A4', 'S4', 'A6', 'RS6', 'allroad', 'A8', 'RS4', 'A3', 'S6', 'S8', 'Q7', 'A5', 'S5', 'R8', 'TTS', 'Q5', 'RS5', 'A7', 'TT RS', 'Q3', 'SQ5', '90',
    '100', 'V8', 'Cabriolet', 'S3', 'S7', 'RS7', 'A4 allroad', '5000', '4000', '80', '200', 'Coupe', 'RS3', 'Q8', 'e-tron',
    'A6 allroad', 'Q5 e', 'A8 e', 'SQ7', 'SQ8', 'RS Q8', 'e-tron Sportback', 'RS e-tron GT', 'Q4', 'A8 L', 'e-tron GT'],
  Ferrari: ['612 Scaglietti', 'F430 Coupe', '599 GTB', 'F430 Spider', '599 GTB Fiorano', '430 Scuderia', 'F430',
    '430 Scuderia Spider', '360 Modena', '360 Spider',
    '575 M Maranello', '456 MGT', '456 MGTA', 'Enzo', 'F355', '550 Maranello', 'F12 Berlinetta', 'California T', 'FF', 'La Ferrari', '458 Italia', '458 Speciale',
    '458 Speciale Spider', '458 Spider', 'California', '599 GTO', '348 tb', '348 ts', '512 TR', '355 Berlinetta', '355 GTS', '348 Spider', '456 GT', '355 Spider',
    'Mondial T', 'Testarossa', 'F40', '328 GTB', '328 GTS', '3.2 Mondial', '328', '308GTB Quattrovalvole', '308GTS Quattrovalvole', 'Mondial 8', '308 Convertible',
    '488 GTB', '488 GTS', 'F12 Special Series', 'F60 America', '308GTBi', '308GTSi', '308GTB', '308GTS', 'F12 tdf (Tour de France)', 'GTC4Lusso', '488 Spider',
    '812 Superfast', 'Portofino', '488 Pista', 'F8 Tributo', '812GTS', 'F8 Spider', 'SF90 Stradale', 'Roma', 'F164BCB', '488 Pista Spider', 'Challenge Stradale',
    'Portofino M', '812 Competizione', 'SF90 Spider', '550 Barchetta', 'F50', '296GTB', 'Purosangue', 'Monza SP1/SP2', 'Daytona SP3', '812 Competizione A', '296 GTS'],
  Koenigsegg: ['Koenigsegg Automotive', 'One:1', 'Agera', 'Regera'],
  McLaren: ['MP4-12C', 'P1', '625C', '650S', '675LT', '540C', '570S', '570GT',
    '600LT', '720S', 'SENNA', 'SENNA GTR', 'GT', '620R', '765LT', 'ELVA', 'ARTURA'],
  Porsche: ['911', 'Boxster', 'Cayenne', 'Cayman', 'Panamera', '918', 'Macan',
    '944', '928', '924', '968', '718', 'Taycan', '718 Boxster', '718 Spyder', '718 Cayman', '718 Boxster Spyder'],
  Bentley: ['Continental GT', 'Brooklands', 'Azure', 'Turbo', 'Mulsanne', 'Flying Spur',
    'Eight', 'Arnage', 'ARMOURED ARNAGE', 'Roll Royce Silver Seraph',
    'Rolls-Royce Park Ward', 'Bentayga', 'Corniche', 'Continental GTC'],
};
export const carMake = [
  'Ford',
  'Chevrolet',
  'Toyota',
  'Honda',
  'Nissan',
  'Jeep',
  'Ram',
  'Hyundai',
  'Subaru',
  'GMC',
  'Dodge',
  'Kia',
  'BMW',
  'Mercedes-Benz',
  'Volkswagen',
  'Lexus',
  'Cadillac',
  'Buick',
  'Acura',
  'Mazda',
  'Audi',
  'Tesla',
  'Chrysler',
  'Infiniti',
  'Volvo',
  'Porsche',
  'Jaguar',
  'Land Rover',
  'Mitsubishi',
  'Mini',
  'Fiat',
  'Alfa Romeo',
  'Genesis',
  'Smart',
  'Ferrari',
  'Maserati',
  'Bentley',
  'Rolls-Royce',
  'Lamborghini',
  'McLaren',
  'Koenigsegg',
  'Bugatti',
];
export const vehicleCat = ['Motorcycles',
  'Cars & Trucks',
  'Campers & RVs',
  'Boats & Marine',
  'Powersport vehicles',
  'Trailers',
  'Tires & Rims',
  'Auto parts & Accessories',
];

export const TiresRims = [
  'Michelin',
  'Goodyear',
  'Bridgestone',
  'Continental',
  'Pirelli',
  'Dunlop',
  'Firestone',
  'Yokohama',
  'Hankook',
  'Toyo',
  'OZ Racing',
  'Vossen',
  'American Racing',
  'Konig',
  'Rotiform',
  'Advan',
  'HRE Performance Wheels',
  'TSW Alloy Wheels',
];

export const Motorcycles = [
  'Yamaha',
  'Honda',
  'Harley-Davidson',
  'BMW',
  'Kawasaki',
  'Ducati',
  'KTM',
  'Triumph',
  'Suzuki',
  'Aprilia',
  'Indian Motorcycle',
  'Moto Guzzi',
  'MV Agusta',
  'Royal Enfield',
  'Benelli',
  'Bajaj',
  'Norton',
  'Victory',
  'Bimota',
  'Hero',
];

export const motorcycleModels = {
  Yamaha: [
    'YZF-R1',
    'MT-09',
    'YZF-R6',
    'FZ-07',
    'Tenere 700',
    'VMAX',
    'Other',
  ],
  Honda: [
    'CBR1000RR',
    'CB650R',
    'Africa Twin',
    'Rebel 500',
    'CRF450L',
    'Gold Wing',
    'Other',
  ],
  'Harley-Davidson': [

    'Street Glide',
    'Fat Boy',
    'Sportster Iron 883',
    'Road Glide',
    'Softail Standard',
    'Pan America 1250',
    'Other',
  ],
  BMW: [
    'S1000RR',
    'R1250GS',
    'F850GS',
    'R nineT',
    'G310R',
    'K1600GT',
    'Other',
  ],
  Kawasaki: [
    'Ninja ZX-10R',
    'Z900',
    'Versys 650',
    'Ninja 650',
    'Z400',
    'KX450',
    'Other',
  ],
  Ducati: [
    'Panigale V4',
    'Monster 821',
    'Multistrada 1260',
    'Diavel',
    'Scrambler Icon',
    'Hypermotard 950',
    'Other',
  ],
  KTM: [
    '1290 Super Duke R',
    '790 Duke',
    '390 Duke',
    '690 Enduro R',
    '1290 Super Adventure R',
    '450 SX-F',
    'Other',
  ],
  Triumph: [
    'Street Triple RS',
    'Bonneville T120',
    'Tiger 800',
    'Speed Twin',
    'Rocket 3',
    'Daytona Moto2 765',
    'Other',
  ],
  Suzuki: [
    'GSX-R1000',
    'SV650',
    'V-Strom 650',
    'GSX-S750',
    'Hayabusa',
    'Boulevard M109R',
    'Other',
  ],
  Aprilia: [
    'RSV4 RR',
    'Tuono V4 1100 Factory',
    'RS 660',
    'Shiver 900',
    'Dorsoduro 900',
    'SR 50',
    'Other',
  ],
  'Indian Motorcycle': [
    'FTR 1200',
    'Scout Bobber',
    'Chieftain',
    'Roadmaster',
    'Chief Vintage',
    'Springfield',
    'Other',
  ],
  'Moto Guzzi': [
    'V85 TT',
    'California Touring 1400',
    'Audace',
    'V7 III Stone',
    'MGX-21',
    'Eldorado',
    'Other',
  ],
  'MV Agusta': [
    'F4 RR',
    'Brutale 800 RR',
    'Dragster 800 RR',
    'Turismo Veloce 800 Lusso',
    'Superveloce 800',
    'Rush 1000',
    'Other',
  ],
  'Royal Enfield': [

    'Classic 500',
    'Bullet 350',
    'Himalayan',
    'Meteor 350',
    'Interceptor 650',
    'Continental GT 650',
    'Other',
  ],
  Benelli: [

    'TNT 300',
    'Leoncino 500',
    'TRK 502',
    '302R',
    'Imperiale 400',
    'Leoncino 800',
    'Other',
  ],
  Bajaj: [

    'Dominar 400',
    'Pulsar NS200',
    'Pulsar RS200',
    'Avenger Cruise 220',
    'Platina 100',
    'Chetak',
    'Other',
  ],
  Norton: [

    'V4RR',
    'Commando 961 Sport MK II',
    'Dominator Street',
    'Atlas Nomad',
    '650 Ranger',
    'Superlight SS',
    'Other',
  ],
  Victory: [

    'Magnum',
    'Octane',
    'Hammer S',
    'Gunner',
    'Vegas',
    'Cross Country',
    'Other',
  ],
  Bimota: [

    'Tesi H2',
    'KB4',
    'DB8',
    'HB4',
    'V-Due',
    'YB11',
    'Other',
  ],
  Hero: [

    'Xtreme 160R',
    'Splendor Plus',
    'Passion Pro',
    'Glamour',
    'XPulse 200',
    'HF Deluxe',
    'Other',
  ],
};

export const CampersRVs = {
  'Travel Trailers': ['Other'],
  'Fifth Wheels': ['Other'],
  'Class A Motorhomes': ['Other'],
  'Class B Motorhomes (Camper Vans)': ['Other'],
  'Class C Motorhomes': ['Other'],
  'Pop-Up Campers': ['Other'],
  'Truck Campers': ['Other'],
  'Teardrop Trailers': ['Other'],
  'Toy Haulers': ['Other'],
  'Park Models': ['Other'],
  'Airstream Trailers': ['Other'],
  'Hybrid Trailers': ['Other'],
  'Bus Conversions': ['Other'],
  'Off-Road RVs': ['Other'],
  'Vintage Trailers': ['Other'],
  'Expandable Trailers': ['Other'],
  'Trailer Tents': ['Other'],
  'Small RVs for Couples': ['Other'],
  'Luxury RVs': ['Other'],
  'Compact Motorhomes': ['Other'],
};

export const BoatsMarine = {
  Powerboats: ['Other'],
  Sailboats: ['Other'],
  'Personal Watercraft': ['Other'],
  'Pontoon Boats': ['Other'],
  Yachts: ['Other'],
  'Fishing Boats': ['Other'],
  'Jet Boats': ['Other'],
  Houseboats: ['Other'],
  Dinghies: ['Other'],
  Canoes: ['Other'],
  Kayaks: ['Other'],
  'Inflatable Boats': ['Other'],
  Catamarans: ['Other'],
  Trawlers: ['Other'],
  'Deck Boats': ['Other'],
  Bowriders: ['Other'],
  Cruisers: ['Other'],
  'Ski Boats': ['Other'],
  'Wakeboard Boats': ['Other'],
  Runabouts: ['Other'],
};

export const Trailers = {
  Trailers: ['Other'],
};

export const AutopartsAccessories = {
  'Auto parts & Accessories': ['Other'],
};

export const tiresRimsModels = {
  Michelin: ['Other'],
  Goodyear: ['Other'],
  Bridgestone: ['Other'],
  Continental: ['Other'],
  Pirelli: ['Other'],
  Dunlop: ['Other'],
  Firestone: ['Other'],
  Yokohama: ['Other'],
  Hankook: ['Other'],
  Toyo: ['Other'],
  'OZ Racing': ['Other'],
  Vossen: ['Other'],
  'American Racing': ['Other'],
  Konig: ['Other'],
  Rotiform: ['Other'],
  Advan: ['Other'],
  'HRE Performance Wheels': ['Other'],
  'TSW Alloy Wheels': ['Other'],
};

export const Powersportvehicles = ['Yamaha', 'Honda', 'Kawasaki', 'Harley Davidson', 'Polaris', 'CanAm', 'SkiDoo', 'ArcticCat', 'SeaDo'];

export const PowersportvehiclesModels = {
  Yamaha: ['YZF-R1', 'YZF-R6', 'MT-07', 'MT-09', 'Grizzly', 'GP1800', 'EX', 'VX', 'SuperJet', 'Kodiak', 'Raptor', 'YFZ450', 'Sidewinder', 'SRViper', 'Apex', 'Vector'],
  Honda: ['CBR1000RR', 'CBR600RR', 'CB500F', 'Africa Twin'],
  Kawasaki: ['Ninja ZX-10R', 'Ninja 650', 'Z900', 'Versys', 'Ultra', 'STX', 'SX-R', 'Jet Ski'],
  HarleyDavidson: ['Sportster', 'Softail', 'Touring', 'Street'],
  Polaris: ['Sportsman', 'Scrambler', 'RZR', 'Ace', 'Indy', 'Rush', 'Switchback', 'Titan'],
  CanAm: ['Outlander', 'Renegade', 'Maverick', 'DS'],

  Suzuki: ['KingQuad', 'QuadSport', 'Ozark', 'Vinson'],
  SkiDoo: ['MXZ', 'Renegade', 'Freeride', 'Summit'],
  ArcticCat: ['ZR', 'M', 'Pantera', 'Mountain Cat'],
  SeaDoo: ['RXP-X', 'GTX', 'Spark', 'Fish Pro'],
};

export const Trailer = ['BigTex', 'CarryOn', 'PJTrailers', 'WellsCargo', 'Airstream', 'Forest River', 'Jayco', 'Winnebago', 'Haulmark', 'Stealth', 'ContinentalCargo', 'LookTrail', 'Sundowner', 'Featherlite', 'Exiss', 'Bison'];

export const trailerMakesAndModels = {
  BigTex: ['10PI', '14PI', '30SA', '35SA'],
  CarryOn: ['3.5X5LSHS', '5X8GW', '6X12GW2BRK'],
  PJTrailers: ['U6', 'U8', 'UVT'],
  WellsCargo: ['FT', 'VT', 'WHD'],
  Airstream: ['Basecamp', 'Flying Cloud', 'International', 'Classic'],
  ForestRiver: ['Rockwood', 'Flagstaff', 'R-Pod', 'Salem'],
  Jayco: ['Jay Flight', 'White Hawk', 'Eagle', 'North Point'],
  Winnebago: ['Micro Minnie', 'Minne Plus', 'Voyage', 'Forza'],
  Haulmark: ['Passport', 'Transport', 'Grizzly', 'Edge'],
  Stealth: ['Phantom', 'Cobra', 'Predator', 'Liberty'],
  ContinentalCargo: ['Tailwind', 'V-Series', 'AutoMaster', 'Haulmark'],
  LookTrailers: ['Element', 'Vision', 'Dlx', 'Puresport'],
  Sundowner: ['Super Sport', 'Sunlite', 'Sunlite Stampede', 'Charter SE'],
  Featherlite: ['9401', '9651', '9751', '8581'],
  Exiss: ['Express', 'Escape', 'STC', 'CX Edition'],
  Bison: ['Ranger', 'Laredo', 'Trail Hand', 'Premier'],

};

export const vehiclesArray = {
  Motorcycles: motorcycleModels,
  'Cars & Trucks': carsObj,
  'Campers & RVs': CampersRVs,
  'Boats & Marine': BoatsMarine,
  'Auto parts & Accessories': AutopartsAccessories,
  'Powersport vehicles': PowersportvehiclesModels,
  'Tires & Rims': tiresRimsModels,
  Trailers: trailerMakesAndModels,
};
