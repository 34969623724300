import React from 'react';
import EmailForm from './EmailForm';

export default function VerifyEmail() {
  return (
    <div className="main-section-div">
      <main className="main-section">
        <EmailForm />
      </main>
    </div>
  );
}
